import type React from 'react';
import { Input } from '@headlessui/react';
import { twMerge } from 'tailwindMerge.config';

interface InputTextProps {
  value?: string | number | readonly string[] | undefined;
  defaultValue?: string | number | readonly string[] | undefined;
  type?: string;
  id?: string;
  name?: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  className?: string;
  children?: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PtnInputText: React.FC<InputTextProps> = ({
  type = 'text',
  className = '',
  disabled = false,
  invalid = false,
  placeholder = '',
  ...props
}) => {
  return (
    <Input
      type={type}
      disabled={disabled}
      invalid={invalid}
      placeholder={placeholder}
      className={twMerge(
        'text-gray-5 box-border h-[54px] bg-gray-100 border-1 border-gray-50 px-1 py-0.25 rounded-12 text-16',
        'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-5',
        'data-[disabled]:bg-gray-60 data-[disabled]:text-gray-80 data-[disabled]:cursor-not-allowed',
        'data-[invalid]:bg-red-90 data-[invalid]:border-red-40',
        className,
      )}
      {...props}
    />
  );
};
